/*--------------------------
    About Styles  
---------------------------*/
.list-numbers {
    margin-left:50px;
    li {position:relative; list-style-type: none;
        h4 {color:var(--color-secondary);
            font-variant:all-petite-caps;
        font-size:40px;}
    }
    .number {
        position:absolute;
        font-size: 30px;
        left: -50px;
        top:5px;
        opacity: .7;
        font-family: var(--secondary-font);
        font-weight: 500;}
}
.about-style-2 {
    background-color:var(--color-gray);
}

.todo span {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
 
  }
  

  .todo:hover {
    filter: drop-shadow(11px 13px 7px gray);
  }

  .todo:hover span {
    letter-spacing: 15px;
  }
.todo-wrapper {
    display:flex; flex-wrap:wrap;
    justify-content: center;
    gap:45px;

    .todo {

        width: 100%;
        min-height: 300px;
        background-size: 100%;
        display: block;
        max-width: calc(50% - 45px);
        position: relative;
        border: 6px solid var(--color-secondary);
        transition: 0.4s;
        background-position: center center;
        background-size: cover;
        overflow: hidden;

    &:after { 
        content:"";
        position:absolute; top:0;right:0;bottom:0;left:0;
        background:inherit;
        transition:inherit;
    }
    &:hover::after { 
        transform: scale(1.2);
    }
    .gradient {
        z-index: 1;
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgb(255, 255, 255) 10%, rgba(255, 255, 255, 0.4261905446) 42%, rgba(255, 255, 255, 0) 62%);
       }

  span {
    z-index:2;
    font-size:120px;
    line-height:1;
    bottom:0px;

    margin:auto;
    text-align:center;
    font-family: var(--primary-font);
    font-weight:800;
    color:var(--color-primary);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
  }

    }
}
.about-style-4 {

    .title {
        font-weight: 400;
        margin-bottom: 35px;
        strong {
            color: var(--color-primary);
        }
    }

    .feature-list {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            display: flex;
            .icon {
                background: var(--color-primary);
                width: 30px;
                height: 30px;
                border-radius: 100%;
                color: var(--color-white);
                text-align: center;
                min-width: 30px;
            }
            .title-wrapper {
                margin-left: 13px;
                .title {
                    font-size: 18px;
                    margin: 0;
                  
                }
                .text {
                    font-size: 16px;
                    margin-bottom: 20px;
                    max-width: 80%;
                    margin-top: 13px;
                }
            }
        }
    }
}


// About Styles 5 

.about-style-5 {
    position: relative;
    z-index: 2;
    .about-wrapper {
        margin-top: -150px;
        background: var(--color-blackest);
        border-radius: 10px;
        padding: 15px;
        @media #{$md-layout} {
            margin-top: 80px;
            padding: 15px 15px 30px 15px;
        }
        @media #{$sm-layout} {
            margin-top: 80px;
            padding: 15px 15px 30px 15px;
        }
        .thumbnail {
            img {
                border-radius: 10px;
            }
        }
        .content {
            .inner {
                padding: 0 40px;
                @media #{$sm-layout} {
                    padding: 0;
                }
                .title {
                    font-size: 30px;
                    margin-bottom: 13px;
                }
                p {
                    margin-bottom: 20px;
                    width: 80%;
                }
            }
        }
    }
}


.contact-address {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        font-size: 16px;
        svg {
            color: var(--color-primary);
            margin-right: 10px;
        }
    }
}


// About Styles 6
.about-style-6 {

    .rbt-progress-style-1,
    .wrapper {
        position: relative;
        z-index: 2;
        max-width: 850px;
        margin: 0 auto;
        border-radius: 10px;
    }
    .wrapper {
        padding: 50px;
        @media #{$md-layout} {
            padding: 20px;
        }
        @media #{$sm-layout} {
            padding: 20px;
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background: var(--color-lessdark);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.6;
            border-radius: 10px;
        }

    }

    

    .thumbnail {
        img {
            max-height: 200px;
        }
    }

    .subtitle {
        font-size: 18px;
        font-weight: 500;
    }
    .maintitle {
        font-size: 30px;
        line-height: 1.3;
        margin-top: 17px;
        margin-bottom: 25px;
    }
}


@media (max-width:768px) {

    .todo-wrapper .todo span {
       font-size:40px; 
       bottom: 40%;
    }
    .todo-wrapper .todo {
        width: 100%;
        min-height: 178px;
        max-width: calc(50% - 20px);
        border-width: 4px;
    
    }

    .swiper-button-disabled {
        opacity:0 !important;
    }
}

.swiper-slide .rn-team {
    opacity:.4;
}

.swiper-slide-active .rn-team {
opacity:1;
}


.swiper-slide-active .rn-team h2 {
    color:var(--orange);
}


.mailchimp-form input {
    min-height:55px;
    margin-bottom:10px;
}

.mailchimp-form input:last-child {
    margin-bottom:60px;
}

.mailchimp-form {
    padding:20px;
    background:var(--blue-three);
}

.mailchimp-form button {
    background: var(--blue);
    outline: none;
    border: 1px solid grey;
    padding: 10px;
    font-size: 30px;
    font-weight: 800;
    font-variant: all-petite-caps;
    padding-bottom: 14px;
    width: 200px;
    color: white;
    margin-top: 23px;
}

.above-fold {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
}

.above-fold-text {
    font-family: "eds-market-main-script", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 135px;
    text-align: center;
    line-height: 0.9;
    text-shadow: 8px 5px 9px #000;
    color: var(--orange);
    max-width: 1100px;
}

 .magazine {
   
}

@media (max-width:768px) { 

    .above-fold-text {
        font-size:60px;
    }
    .magazine {
        position: relative;
        width: 100%;
        top: unset;
        left: unset;
    }
    .todo-wrapper {
        gap:15px;
    }

}

.rn-event-area h2 {
    margin-bottom:50px !important;
}

.mc-field-group input {
    min-height:50px;
}