/*-----------------------
    Footer Style Here  
------------------------*/
.logo {
    a {
        
    }
}

.footer-style-default {
    border-top: 1px solid var(--color-border);
    background-color: var(--color-gray);
    .footer-top {
        padding: 100px 0;
        padding-bottom: 70px;
        .rn-footer-widget {
            .text-big {
                font-size: 21px;
                margin-top: 30px;
                padding-right: 13%;
                line-height: 1.7;
                font-weight: 500;
                color: var(--color-body);
                margin-bottom: 30px;
                
                @media #{$md-layout} {
                    font-size: 20px;
                    padding-right: 0;
                }
                @media #{$sm-layout} {
                    font-size: 20px;
                    padding-right: 0;
                }
            }

            .title {
                font-size: 18px;
                font-weight: 800;
                letter-spacing: 1px;
                margin-bottom: 10px;
                color: var(--color-primary);
            }

            .subtitle {
                font-weight: 400;
                color: var(--color-primary);
                font-size: 16px;
                padding-right: 31%;
                line-height: 28px;
                margin-bottom: 32px;
                @media #{$sm-layout} {
                    padding-right: 0;
                }
            }
            
            .inner {
                margin-top: 13px;
            }

            .footer-link {
                list-style: none;
                padding: 0;
                li {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    a {
                        font-size: 14px;
                        display: inline-block;
                        color: var(--color-primary);
                        line-height: 25px;
                        transition: 0.3s;
                        &:hover {
                            color: var(--color-primary);
                            &::after {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                }
            }

        }
    }
    
    .logo {
        a {
           
        }
    }

    &.variation-two{
        border-top: 0;
    }

    &.no-border {
        border-top: 0;
    }
}

.footer-style-2 {
    .copyright-style-one {
        background: transparent;
    }
}




// Newsletter Styles 
.newsletter-form {
    display: flex;
    flex-wrap: wrap;
    input {
        flex-grow: 1;
        width: auto;
        margin-right: 10px;
        height: 50px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
}

.footer-style-default {
    .newsletter-form {
        .form-group {
            input {
                color: var(--color-primary);
                border-color: var(--color-primary);
            }
        }
    }
}

.footer-form .mailchimp-form input {
    color:var(--blue);
    border-color:var(--blue)
}

.footer-form .mailchimp-form button {
    background: transparent;
    outline: none;
    border: 2px solid grey;
    color: var(--blue);
    padding: 5px;
    font-size: 22px;
    font-weight: 800;
    font-feature-settings: "pcap", "c2pc";
    font-variant: all-petite-caps;
    padding-bottom: 10px;
    width: 200px;
    /* color: white; */
    margin-top: 23px;   
}

.footer-top .logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin:20px;
}
.four-wrap {
    max-width:500px;
    margin:auto;
    text-align:center;
    display:flex;
    min-height:calc(100vh - 200px);
    flex-direction: column;
    justify-content: center;
}

@media (max-width:768px) {
    .footer-top .logo {
        max-width:250px;
        margin:auto;
    }
    .footer-style-default .footer-top {
        padding-left:10px;
        padding-right:10px;
        padding-bottom:0px;
    }

    .footer-top .row > div:last-child {
        background:rgb(199, 199, 199);
        padding-top:20px;
        text-align: center;
    }
}