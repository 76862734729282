
 .event-item {
    transition:.2s;
    position:relative; max-width:250px; margin:auto; margin-bottom:20px; background:white; border-radius:20px; position:relative; display:block !Important; overflow:hidden;  line-height:1;

    .event-name {font-weight:900;background: var(--color-primary); color:white; text-align:center; padding:3px; display:flex; justify-content:center; align-items:center; min-height:60px;
        letter-spacing: 1px;}
    
    .event-time {display:flex; 
                flex-direction:column; 
                min-height:170px; 
                padding:5px; 
                border-left:6px solid var(--color-secondary); 
                border-right:6px solid var(--color-secondary); 

                border-top:none; 
          
        span {color:var(--color-primary); text-align:center; font-family: var(--font-primary); 
            font-weight:400; font-variant:all-petite-caps;}
        .event-month {font-size:50px; font-weight:500;}
        .event-day {font-weight:700; font-size:120px; position:relative; margin-top:-30px;}

    }
    a {
        position:absolute;
        display:block;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:1
    }
    .event-time span {
        transition:.3s;
    }

    .event-cta {
        border-bottom-right-radius:20px;
        border-bottom-right-radius:20px;
        text-align:center; 
        padding:3px;
        background-color:var(--color-secondary);
        color:white;
        display:flex; justify-content:center; align-items:center;
        min-height:40px;
        font-weight:800;
        letter-spacing:1px;
    }
   &:hover {
    box-shadow: 0px 5px 10px gray;
   } .event-time span
   {transform:scale(1.1)}
 }


 .featured-event-item {
    box-shadow: 0px 5px 10px gray;
    margin-bottom:20px !important;
    transition:.2s;
    background-size:cover;
    background-position:center center;
    position:relative; max-width:350px; margin:auto; position:relative; display:block !Important; overflow:hidden;  line-height:1; 

    &>div {
        min-height:250px;
        background-size:cover;
        background-position:center -20px;
    }

    .event-time {
        background:var(--color-primary);
        color:white;
        text-align:center;
        padding:10px;
        font-family: var(--font-primary);
        font-weight:800;
        font-size:30px;
    }
    .event-day {
        color:var(--color-secondary);
    }
 }

 .featured-event-item {
    position:relative;
 }

 .featured-event-item a {
    position:absolute;
    display:block;
    top:0;
    left:0;
    width:100%;
    height:100%;
 }

 .slick-track {padding-top:30px;padding-bottom:30px;}

 .rn-event-area {background-color:#ffffffde;}
 .rn-featured-event-area {background-color:white;}